import { Component, Inject, OnInit } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SkillsService } from '@lib/frontend-shared-skill-data-skill-service';
import { LOGIN_AS_COOKIE_NAME } from '@lib/shared-interface-account';
import { DOCUMENT } from '@angular/common';

@Component({
    standalone: true,
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    imports: [IonicModule],
    providers: [
        // This might be possible at the app providers level
        // but the following article shows it in component providers:
        // https://ionic.zendesk.com/hc/en-us/articles/10386373742231-Angular-Standalone-Components-with-Ionic
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
    ],
})
export class AppComponent implements OnInit {
    protected cookie?: string;

    public constructor(
        private skillsService: SkillsService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    public ngOnInit() {
        void this.skillsService.prefetchData();
        if (this.document.cookie.includes(LOGIN_AS_COOKIE_NAME)) {
            const userEmail = this.document.cookie
                ?.split('; ')
                ?.find((row) => row.startsWith(LOGIN_AS_COOKIE_NAME))
                ?.split('=')[1];
            if (userEmail) this.cookie = decodeURIComponent(userEmail);
        }
    }
}
